import { Constants } from './../constants/constants';
import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class BaseService {
  public http: HttpClient;
  public headers: HttpHeaders;
  public baseConstants: Constants;

    constructor(private inject: Injector ) {
        this.http = inject.get(HttpClient);
        this.baseConstants = inject.get(Constants);
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json;' });
    }

}
