import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HomeService } from './../../core/services/home.service';
import { Contenidos, NoticiasView } from './../../core/interfaces/home.models';
import { ResultViewModel } from './../../core/interfaces/respuesta.interface';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  quienesSomos: Contenidos[] = [];
  mision: Contenidos[] = [];
  vision: Contenidos[] = [];
  politicas: Contenidos[] = [];
  objetivos: Contenidos[] = [];
  principios: Contenidos[] = [];
  portafolio: Contenidos[] = [];
  portafolioCat: Contenidos[] = [];
  portafolioEsp: Contenidos[] = [];
  trabajaCN: Contenidos[] = [];
  noticia: NoticiasView;
  noticiasList: NoticiasView[];

  constructor( private homeService: HomeService ) { }

  ngOnInit() {
    this.homeService.getQuienesSomos().subscribe((result: ResultViewModel) => {
      this.quienesSomos = result.data;
    }, error => console.error(error));

    this.homeService.getMision().subscribe((result: ResultViewModel) => {
      this.mision = result.data;
    }, error => console.error(error));

    this.homeService.getVision().subscribe((result: ResultViewModel) => {
      this.vision = result.data;
    }, error => console.error(error));

    this.homeService.getPoliticas().subscribe((result: ResultViewModel) => {
      this.politicas = result.data;
    }, error => console.error(error));

    this.homeService.getObjetivos().subscribe((result: ResultViewModel) => {
      this.objetivos = result.data;
    }, error => console.error(error));

    this.homeService.getPrincipios().subscribe((result: ResultViewModel) => {
      this.principios = result.data;
    }, error => console.error(error));

    this.homeService.getPortafolio().subscribe((result: ResultViewModel) => {
      this.portafolio = result.data;
    }, error => console.error(error));

    this.homeService.getPortafolioCategorias().subscribe((result: ResultViewModel) => {
      this.portafolioCat = result.data;
    }, error => console.error(error));

    this.homeService.getPortafolioEspecial().subscribe((result: ResultViewModel) => {
      this.portafolioEsp = result.data;
    }, error => console.error(error));

    this.homeService.getUltimaNoticia().subscribe((result: ResultViewModel) => {
      this.noticia = result.data;
    }, error => console.error(error));

    this.homeService.getTrabajaCN().subscribe((result: ResultViewModel) => {
      this.trabajaCN = result.data;
    }, error => console.error(error));

  }

}
