import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';
import { Constants } from './../constants/constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Contenidos, Enlaces, NoticiasView } from './../interfaces/home.models';


@Injectable({providedIn: 'root'})

export class HomeService extends BaseService {

  constructor(private injector: Injector) {
    super(injector);
  }
  apiEnlaces = this.baseConstants.API_ROOT + '/HomePage/Enlaces';
  apiCodContenido = this.baseConstants.API_ROOT + '/HomePage/ContenidoXcodigo/';
  apiNoticias = this.baseConstants.API_ROOT + '/HomePage/NoticiasXtipo/';
  apiUltimaNoticia = this.baseConstants.API_ROOT + '/HomePage/NoticiaUltimaGeneral/';

  getQuienesSomos() {
    return this.http.get(this.apiCodContenido + 'QuienesSomos/');
  }

  getMision() {
    return this.http.get(this.apiCodContenido + 'Mision/');
  }

  getVision() {
    return this.http.get(this.apiCodContenido + 'Vision/');
  }

  getPoliticas() {
    return this.http.get(this.apiCodContenido + 'Politicas/');
  }

  getObjetivos() {
    return this.http.get(this.apiCodContenido + 'Objetivos/');
  }

  getPrincipios() {
    return this.http.get(this.apiCodContenido + 'Principios/');
  }

  getPortafolio() {
    return this.http.get(this.apiCodContenido + 'Portafolio/');
  }

  getPortafolioCategorias() {
    return this.http.get(this.apiCodContenido + 'PortafolioCategorias/');
  }

  getPortafolioEspecial() {
    return this.http.get(this.apiCodContenido + 'PortafolioEspecial/');
  }

  getClientes() {
    return this.http.get(this.apiCodContenido + 'Clientes/');
  }

  getHistoria() {
    return this.http.get(this.apiCodContenido + 'Historia/');
  }

  getTrabajaCN() {
    return this.http.get(this.apiCodContenido + 'TrabajaCN/');
  }

  getEnlaces() {
    return this.http.get(this.apiEnlaces);
  }

  getNoticias(id: any) {
    return this.http.get(this.apiNoticias + id);
  }

  getUltimaNoticia() {
    return this.http.get(this.apiUltimaNoticia, {headers: this.headers});
  }

}
