import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './site/home/home.component';
import { HistoryComponent } from './site/history/history.component';
import { ClientsComponent } from './site/clients/clients.component';
import { EnlacesComponent } from './site/enlaces/enlaces.component';
import { NewsComponent } from './site/news/news.component';
import { BenefitsComponent } from './site/news/benefits.component';
import { WorksComponent } from './site/news/works.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'home', component: HomeComponent},
  {path: 'history', component: HistoryComponent},
  {path: 'clients', component: ClientsComponent},
  {path: 'enlaces', component: EnlacesComponent},
  {path: 'news', component: NewsComponent},
  {path: 'benefits', component: BenefitsComponent},
  {path: 'works', component: WorksComponent},
  {path: '**', component: HomeComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
