import { Component, OnInit } from '@angular/core';
import { HomeService } from './../../core/services/home.service';
import { Contenidos } from './../../core/interfaces/home.models';
import { ResultViewModel } from './../../core/interfaces/respuesta.interface';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html'
})
export class ClientsComponent implements OnInit {
  clientes: Contenidos[] = [];
  constructor(private homeService: HomeService) { }

  ngOnInit() {
    this.homeService.getClientes().subscribe((result: ResultViewModel) => {
      this.clientes = result.data;
    }, error => console.error(error));
  }
}
