import { Component, OnInit } from '@angular/core';
import { HomeService } from './../../core/services/home.service';
import { Contenidos } from './../../core/interfaces/home.models';
import { ResultViewModel } from './../../core/interfaces/respuesta.interface';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {
  historia: Contenidos[];

  constructor( private homeService: HomeService ) { }

  ngOnInit() {
    this.homeService.getHistoria().subscribe((result: ResultViewModel) => {
      this.historia = result.data;
    }, error => console.error(error));
  }

}
