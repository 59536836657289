import { Component, OnInit } from '@angular/core';
import { HomeService } from './../../core/services/home.service';
import { Enlaces } from './../../core/interfaces/home.models';
import { ResultViewModel } from './../../core/interfaces/respuesta.interface';

@Component({
  selector: 'app-enlaces',
  templateUrl: './enlaces.component.html'
})
export class EnlacesComponent implements OnInit {
  enlace: Enlaces[];
  constructor( private homeService: HomeService ) { }

  ngOnInit() {
    this.homeService.getEnlaces().subscribe((result: ResultViewModel) => {
      this.enlace = result.data;
    }, error => console.error(error));
  }
}
