import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResultViewModel } from './../../core/interfaces/respuesta.interface';
import { NoticiasView } from './../../core/interfaces/home.models';
import { HomeService } from './../../core/services/home.service';

@Component({
  selector: 'app-works',
  templateUrl: 'works.component.html'
})

export class WorksComponent implements OnInit {
  tipoNoticia: number;
  noticias: NoticiasView[];
  constructor(private homeService: HomeService, private route: ActivatedRoute) {
    this.cargarNoticias(4);
  }

  ngOnInit() {
  }

  cargarNoticias(id: number) {
    this.homeService.getNoticias(id).subscribe((result: ResultViewModel) => {
      this.noticias = result.data;
    }, error => console.error(error));
  }
}
