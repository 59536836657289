import { Injectable } from '@angular/core';

Injectable();

export class Constants {
    public API_ROOT: string;
    public API_SECURITY_Controller = 'security';
    public CONTENT_TYPE_JSON = 'application/json';

    constructor() {
        // Do not include the host here as this will break when deployed...
        // Note: we may need to adjust this regex for devs using a local IIS instance
        let re = /localhost:/gi;
        // console.log(window.location.origin.search(re));
        // console.log(window.location.origin);
        if (window.location.origin.search(re) >= 0) {
            // This was run locally... IIS Express doesn't allow us to root things under a sub folder,
            // so the non-webpack serviced items are at the root rather than under AppliedDX
            this.API_ROOT = 'https://localhost:5001/api';
            //this.API_ROOT = 'https://proensaludadmin.azurewebsites.net/api/api';
        } else {
            this.API_ROOT = 'https://proensaludadmin.azurewebsites.net/api/api';
        }
    }
}
