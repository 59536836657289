import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResultViewModel } from './../../core/interfaces/respuesta.interface';
import { NoticiasView } from './../../core/interfaces/home.models';
import { HomeService } from './../../core/services/home.service';

@Component({
  selector: 'app-benefits',
  templateUrl: 'benefits.component.html'
})

export class BenefitsComponent implements OnInit {
  tipoNoticia: number;
  noticias: NoticiasView[];
  constructor(private homeService: HomeService, private route: ActivatedRoute) {
    this.cargarNoticias(2);
  }

  ngOnInit() {
  }

  cargarNoticias(id: number) {
    this.homeService.getNoticias(id).subscribe((result: ResultViewModel) => {
      this.noticias = result.data;
    }, error => console.error(error));
  }
}
