import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/layout/header/header.component';
import { SliderComponent } from './shared/layout/slider/slider.component';
import { FooterComponent } from './shared/layout/footer/footer.component';
import { HomeComponent } from './site/home/home.component';
import { HistoryComponent } from './site/history/history.component';
import { Constants } from './core/constants/constants';
import { ClientsComponent } from './site/clients/clients.component';
import { EnlacesComponent } from './site/enlaces/enlaces.component';
import { NewsComponent } from './site/news/news.component';
import { BenefitsComponent } from './site/news/benefits.component';
import { WorksComponent } from './site/news/works.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SliderComponent,
    FooterComponent,
    HomeComponent,
    HistoryComponent,
    ClientsComponent,
    EnlacesComponent,
    NewsComponent,
    BenefitsComponent,
    WorksComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [Constants],
  bootstrap: [AppComponent]
})
export class AppModule { }
